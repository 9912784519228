<template>
  <div class="layout-default">
    <MainHeader />
    <main>
      <slot></slot>
    </main>
    <MainFooter />
  </div>
</template>

<script setup lang="ts">
import MainHeader from '@/components/layout/MainHeader.vue';
import MainFooter from '@/components/layout/MainFooter.vue';

useHead({
  bodyAttrs: {
    class: 'sandy sandy-full',
  },
});
</script>

<style scoped>
.layout-default {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  main {
    flex-grow: 1;
  }
}
</style>
